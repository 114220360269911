<template>
    <div>
        <h3>Kártyáim</h3>
        <b-row>
            <b-col md="3">
                <b-form v-on:submit.prevent="submitFile">
                    <b-form-file
                        v-model="file"
                        :state="Boolean(file)"
                        placeholder="Fájl feltöltés"
                        drop-placeholder="Fájl feltöltés"
                        ref="file"
                        v-on:change="handleFileUpload()"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ></b-form-file>
                    <div class="mt-3">
                        Kiválasztott fájl: {{ file ? file.name : "" }}
                    </div>
                    <b-button type="submit" variant="primary">Mentés</b-button>
                </b-form>
            </b-col>
            <b-col md="9">
                <b-row>
                    <b-col>
                        <b-form-group
                            id="input-group-1"
                            label="SET"
                            label-for="input-1"
                        >
                            <b-form-input
                                id="input-1"
                                v-model="filter.set"
                                v-on:keyup="startFilter"
                                v-on:change="startFilter"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                            id="input-group-1"
                            label="Kártya neve"
                            label-for="input-1"
                        >
                            <b-form-input
                                id="input-1"
                                v-model="filter.name"
                                v-on:keyup="startFilter"
                                v-on:change="startFilter"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                            id="input-group-1"
                            label="Kiadás száma"
                            label-for="input-1"
                        >
                            <b-form-input
                                id="input-1"
                                v-model="filter.collector_number"
                                v-on:keyup="startFilter"
                                v-on:change="startFilter"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="table-responsive">
                    <table class="table">
                        <tr>
                            <th>SET</th>
                            <th>Név</th>
                            <th>Kép</th>
                            <th>Kiadás száma</th>
                            <th>Foil?</th>
                            <th>Minőség</th>
                            <th>Mennyiség</th>
                        </tr>
                        <tr
                            v-for="(item, index) in items"
                            v-bind:key="'tr-' + index"
                        >
                            <td>{{ item.card.set }}</td>
                            <td>{{ item.card.name }}</td>
                            <td>
                                <b-img
                                    :src="item.card.image_uris.small"
                                ></b-img>
                            </td>
                            <td>{{ item.card.collector_number }}</td>
                            <td>{{ item.is_foil ? "Igen" : "Nem" }}</td>
                            <td>{{ getQuality(item.quality) }}</td>
                            <td>{{ item.quantity }}</td>
                        </tr>
                    </table>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "MyCards",
    data() {
        return {
            file: null,
            items: [],
            filter: {
                set: null,
                name: null,
                collector_number: null
            },
            timeOut: null
        };
    },
    beforeMount() {
        this.getCards();
    },
    methods: {
        getQuality(quality) {
            switch (parseInt(quality)) {
                case 1:
                    return "Near Mint";
                case 2:
                    return "Mint";
                case 3:
                    return "Played";
                case 4:
                    return "Poor";
            }
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        submitFile() {
            let formData = new FormData();
            formData.append("file", this.file);

            ApiService.post(`card/upload-csv`, formData, true).finally(() => {
                this.getCards();
            });
        },
        startFilter() {
            const self = this;
            self.timeOut = null;
            self.timeOut = setTimeout(function() {
                self.getCards();
            }, 1000);
        },
        getCards() {
            ApiService.query(
                `card/cards/?${this.encodeQueryData(this.filter)}`
            ).then(resp => {
                this.items = resp.data.data;
            });
        },
        encodeQueryData(data) {
            const ret = [];
            for (const d in data) {
                if (
                    data[d] &&
                    data[d] !== null &&
                    data[d] !== undefined &&
                    data[d] !== ""
                ) {
                    ret.push(
                        encodeURIComponent(d) +
                            "=" +
                            encodeURIComponent(data[d])
                    );
                }
            }
            return ret.join("&");
        }
    }
};
</script>

<style scoped></style>
